<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <sidebar-group title="List|Liste">
            <b-form-group>
                <b-btn v-if="block.sortEnabled" @click="toggleSortOrder" class="float-right" variant="outline-white" style="margin-top: -4px; position: relative; z-index: 2;">
                    <i class="fas" :class="block.sortOrder === 'asc' ? 'fa-arrow-down-a-z' : 'fa-arrow-up-a-z'"/>
                </b-btn>

                <b-checkbox v-model="block.sortEnabled" switch>
                    <span v-tr>Sort Items|Trier les éléments</span>
                </b-checkbox>
            </b-form-group>

            <b-form-group v-if="block.sortEnabled" label-cols="4" :label="'Sort By|Trier par'|tr">
                <select-cms-item-field :item-type="block.itemType" v-model="block.sortBy" field-type="any-text"/>
            </b-form-group>

            <b-form-group>
                <b-btn v-if="block.groupEnabled" @click="toggleGroupOrder" class="float-right" variant="outline-white" style="margin-top: -4px; position: relative; z-index: 2;">
                    <i class="fas" :class="block.groupOrder === 'asc' ? 'fa-arrow-down-a-z' : 'fa-arrow-up-a-z'"/>
                </b-btn>

                <b-checkbox v-model="block.groupEnabled" switch>
                    <span v-tr>Enable Group By|Regrouper les éléments</span>
                </b-checkbox>
            </b-form-group>

            <b-form-group v-if="block.groupEnabled" label-cols="4" :label="'Group By|Grouper par'|tr">
                <select-cms-item-field :item-type="block.itemType" v-model="block.groupBy" field-type="any-text"/>
            </b-form-group>
        </sidebar-group>

        <template #layout>

            <b-form-group>
                <btn-responsive-prop v-model="block" field="borderRadius" no-label/>
                <input-border-radius v-model="block.mediaProxy.borderRadius"/>
            </b-form-group>

            <b-form-group v-if="block.mediaProxy.layout !== 'default'" label-cols="4">
                <template #label>
                    <span v-tr>Columns|Colonnes</span>
                    <btn-responsive-prop v-model="block" field="columns"/>
                </template>
                <b-select v-model.number="block.mediaProxy.columns">
                    <option v-for="n in 5" :key="`columns-${n}`" :value="`${n}`">{{n}}</option>
                </b-select>
            </b-form-group>
            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Spacing|Espacement</span>
                    <btn-responsive-prop v-model="block" field="spacing"/>
                </template>
                <b-select v-model="block.mediaProxy.spacing">
                    <option value="0" v-tr>None|Sans</option>
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>
        </template>

        <properties-component-border title="Divider|Séparateur" :value="block.divider" v-bind="componentProps"/>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";

// properties-block-list #hot-reload-debug
export default {
    name: `properties-block-list`,
    components: {SelectCmsItemField, SidebarGroup, InputBorderRadius, PropertiesBlock},
    extends: PropertiesBlock,
    methods: {
        toggleSortOrder() {
            if (this.block.sortOrder === `asc`) {
                this.$set(this.block, `sortOrder`, `desc`);
            } else {
                this.$set(this.block, `sortOrder`, `asc`);
            }
        },
        toggleGroupOrder() {
            if (this.block.groupOrder === `asc`) {
                this.$set(this.block, `groupOrder`, `desc`);
            } else {
                this.$set(this.block, `groupOrder`, `asc`);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
